import { ref, watch, toRefs, reactive } from 'vue';
import { useRoute } from 'vue-router';
import qs from 'qs';
export default {
  name: 'App',
  components: {},

  setup() {
    const route = useRoute();
    const routerKey = ref();
    const state = reactive({
      keepAliveNameList: []
    });
    watch(() => route, newVal => {
      if (newVal.meta.keepAlive && state.keepAliveNameList.indexOf(newVal.name) === -1) {
        state.keepAliveNameList.push(newVal.name);
      }

      const fullPath = route.query && Object.keys(route.query).length ? `${route.path}&${qs.stringify(route.query)}` : route.path;
      routerKey.value = fullPath;
    }, {
      deep: true
    });
    return { ...toRefs(state),
      routerKey
    };
  }

};