import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  // {
  //   path: '/',
  //   name: 'Root',
  //   component: () => import('@/views/main/index.vue'),
  // },
  {
    path: '/scan',
    name: 'Scan',
    component: () => import('@/views/scan/index.vue'),
    meta: {
      title: '扫码',
      keepAlive: true,
    },
  },
  {
    path: '/scan2',
    name: 'Scan2',
    component: () => import('@/views/scan2/index.vue'),
    meta: {
      title: '扫码',
      keepAlive: true,
    },
  },
  {
    path: '/code',
    name: 'Code',
    component: () => import('@/views/code/index.vue'),
    meta: {
      title: '挪车码',
    },
  },
  {
    path: '/car',
    name: 'Car',
    component: () => import('@/views/car/index.vue'),
    meta: {
      title: '挪车',
    },
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/index.vue'),
    meta: {
      title: '我的订单',
    },
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('@/views/shop/index.vue'),
    meta: {
      title: '商城',
    },
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user/index.vue'),
    meta: {
      title: '个人中心',
      keepAlive: true,
    },
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import('@/views/question/index.vue'),
    meta: {
      title: '常见问题',
      keepAlive: true,
    },
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import('@/views/wallet/index.vue'),
    meta: {
      title: '我的钱包',
    },
  },
  {
    path: '/trans',
    name: 'TransDetail',
    component: () => import('@/views/wallet/transDetail.vue'),
    meta: {
      title: '交易明细',
    },
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: () => import('@/views/scan/deposit.vue'),
    meta: {
      title: '隐私保护服务',
    },
  },
]
const router = createRouter({
  // base: process.env.BASE_URL,
  history: createWebHistory(),
  routes,
})

// reset router
// export function resetRouter() {
//   router.getRoutes().forEach((route) => {
//     const { name } = route
//     if (name) {
//       router.hasRoute(name) && router.removeRoute(name)
//     }
//   })
// }

export default router
